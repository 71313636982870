<template>
  <div>
    <h1 class="calculator__header">Aflevering</h1>
    <p class="calculator__description">Alle fronter skal pakkes ind og markeres på indpakningen, så de ikke behøver pakkes op før på montering.</p>

    <div class="calculator__container">
      <div class="calculator__card">
        <div class="input__container">
          <label class="input__label">Kontakt person</label>
          <input class="input" type="text" placeholder="Indtast fuldt navn" v-model="contactName" />
        </div>
        <div class="input__container">
          <label class="input__label">Firma navn</label>
          <input class="input" type="text" placeholder="Indtast firma navn" v-model="company" />
        </div>
        <div class="input__container">
          <label class="input__label">Addresse</label>
          <input class="input" type="text" placeholder="Indtast adresse" v-model="address" />
        </div>
        <div class="input__container">
          <label class="input__label">Email adresse</label>
          <input class="input" type="text" placeholder="Indtast email adresse" v-model="email" />
        </div>
        <div class="input__container">
          <label class="input__label">Telefonnummer</label>
          <input class="input" type="number" placeholder="Indtast telefonnummer" v-model="phoneNumber" />
        </div>
      </div>
      <div class="calculator__grid">
        <h2 class="calculator__subheader">Ønsket montage tidspunkt</h2>
        <Datepicker :clear-button="true" :language="da" :full-month-name="true" placeholder="Vælg dato" v-model="installationDate" calendar-button-icon-content="event"></Datepicker>
      </div>
      <div class="calculator__grid">
        <h2 class="calculator__subheader">Tilføj billeder af opgaven</h2>
        <ImageUpload :index="0" label="Plantegning"></ImageUpload>
        <ImageUpload :index="1" label="Opstalt"></ImageUpload>
        <ImageUpload :index="2" label="Detaljetegning"></ImageUpload>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { da } from 'vuejs-datepicker/dist/locale'
import { mapFields } from 'vuex-map-fields'

import ImageUpload from '@/components/calculator/ui/ImageUpload'

export default {
  name: 'Delivery',
  data() {
    return {
      da: da,
    }
  },
  components: {
    Datepicker,
    ImageUpload,
  },
  computed: {
    ...mapFields([
      'project.delivery.contactName',
      'project.delivery.company',
      'project.delivery.address',
      'project.delivery.email',
      'project.delivery.phoneNumber',
      'project.delivery.installationDate',
      'project.delivery.installationTime',
    ]),
  },
}
</script>

<style lang="scss">
@import '@/assets/shared-styles/datepicker.scss';
</style>

<style lang="scss" scoped>
@import '@/assets/shared-styles/calculator.scss';
@import '@/assets/shared-styles/select.scss';
@import '@/assets/shared-styles/radio.scss';
@import '@/assets/shared-styles/input.scss';
</style>
