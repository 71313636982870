<template>
  <div>
    <h1 class="calculator__header">Anmodning overblik</h1>
    <p class="calculator__description">Alle fronter skal pakkes ind og markeres på indpakningen, så de ikke behøver pakkes op før på montering.</p>

    <div class="calculator__container">
      <div class="calculator__options-card">
        <h2 class="calculator__subheader calculator__subheader--no-margin-top">Opstart</h2>
        <div class="calculator__options"></div>
        <span class="calculator__label">Estimeret pris eks. moms</span>
        <span class="calculator__price">{{ $store.getters.getFirstPhasePrice }} DKK</span>
      </div>
      <template v-for="step in selectedOptionalSteps">
        <div class="calculator__options-card" v-for="instance in step.instances" :key="`${step.id}-${instance}`">
          <h2 class="calculator__subheader calculator__subheader--no-margin-top">{{ $store.getters.optionalStepNameBySlug(step.slug) }} ({{ instance }})</h2>
          <div class="calculator__options">
            <span class="calculator__option" @click="onEdit(step.slug, instance)">Rediger</span>
            <span class="calculator__option" @click="onRemove(step, instance)">Fjern</span>
          </div>
          <span class="calculator__label">Estimeret pris eks. moms</span>
          <span class="calculator__price">{{ $store.getters.getPrice(step.slug, instance) }} DKK</span>
        </div>
      </template>

      <div class="textarea__container textarea__container--no-top-padding">
        <textarea rows="6" class="textarea" placeholder="Skriv en kommentar..." type="text" v-model="comment"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'overview',
  methods: {
    ...mapActions(['removeStep', 'deSelectedOptionalStep']),
    onEdit(slug, instance) {
      this.$router.push(`/calculator/${slug}/${instance}`)
    },
    onRemove(step, instance) {
      this.$confirm({
        message: `Er du sikker på du vil fjerne ${this.$store.getters.optionalStepNameBySlug(step.slug).toLowerCase()}?`,
        button: {
          yes: 'Ja',
          no: 'Nej',
        },
        callback: confirm => {
          if (confirm) {
            const payload = { step: step, instance: instance }
            this.removeStep(payload)
            this.deSelectedOptionalStep(payload)
          }
        },
      })
    },
  },
  computed: {
    ...mapGetters(['optionalSteps', 'getPrice', 'getFirstPhasePrice']),
    ...mapFields(['project.comment']),
    selectedOptionalSteps() {
      return this.optionalSteps.filter(step => step.selected)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared-styles/calculator.scss';
@import '@/assets/shared-styles/textarea.scss';
</style>
