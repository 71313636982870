<template>
  <div class="calculator__wrapper">
    <Steps v-if="this.$vssWidth > 1440"></Steps>

    <div v-if="this.$route.params.step != 'success'" class="calculator__actions">
      <BackButton></BackButton>
      <Clear></Clear>
    </div>

    <FirstPhase v-if="this.$route.params.step == 'first-phase'"></FirstPhase>
    <OptionalStep v-if="$store.getters.stepIsOptional(this.$route.params.step)"></OptionalStep>
    <Delivery v-if="this.$route.params.step == 'delivery'"></Delivery>
    <Overview v-if="this.$route.params.step == 'overview'"></Overview>
    <Success v-if="this.$route.params.step == 'success'"></Success>

    <NextButton v-if="this.$route.params.step != 'success'"></NextButton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import VueScreenSize from 'vue-screen-size'

import FirstPhase from '@/components/calculator/steps/FirstPhase'
import OptionalStep from '@/components/calculator/steps/OptionalStep'
import Delivery from '@/components/calculator/steps/Delivery'
import Overview from '@/components/calculator/steps/Overview'
import Success from '@/components/calculator/steps/Success'

import Clear from '@/components/calculator/ui/Clear'
import NextButton from '@/components/calculator/ui/NextButton'
import BackButton from '@/components/calculator/ui/BackButton'
import Steps from '@/components/calculator/ui/Steps'

export default {
  name: 'Calculator',
  components: {
    FirstPhase,
    OptionalStep,
    Delivery,
    Overview,
    Success,
    Clear,
    BackButton,
    NextButton,
    Steps,
  },
  mixins: [VueScreenSize.VueScreenSizeMixin],
  mounted() {
    if (!this.$store.getters.stepIsOptional(this.$route.params.step) && !this.isRequired) this.$router.push({ path: 'first-phase/1' })
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    isRequired() {
      return this.$route.params.step == 'first-phase' || this.$route.params.step == 'delivery' || this.$route.params.step == 'overview'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared-styles/calculator.scss';
@import '@/assets/shared-styles/select.scss';
@import '@/assets/shared-styles/radio.scss';

.login {
  text-decoration: none;
  color: #999999;
}
</style>
