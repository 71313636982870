<template>
  <div>
    <h1 class="calculator__header">Opstart</h1>
    <p class="calculator__description">Alle fronter skal pakkes ind og markeres på indpakningen, så de ikke behøver pakkes op før på montering.</p>

    <div class="calculator__card">
      <div class="select-container">
        <label for="zip">Postnummer for montage</label>
        <select id="zip" required v-model="danishZip">
          <option value="" disabled selected>Vælg postnummer</option>
          <option v-for="(zip, index) in zips" :value="zip" :key="index">
            {{ zip }}
          </option>
        </select>
        <svg width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5.5 4L10 1" stroke="#474746" /></svg>
      </div>
      <div class="select-container">
        <label for="home-type">Bolig type</label>
        <select id="home-type" required v-model="homeType">
          <option value="" disabled selected>Vælg type</option>
          <option v-for="(home, index) in homeTypes" :value="home" :key="index">
            {{ home }}
          </option>
        </select>
        <svg width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5.5 4L10 1" stroke="#474746" /></svg>
      </div>
      <div v-if="homeType == 'Lejlighed' || homeType == ''" class="select-container">
        <label for="level">Etage</label>
        <select id="level" required v-model="level">
          <option value="" disabled selected>Vælg etage</option>
          <option v-for="(level, index) in levels" :value="level" :key="index">
            {{ level.name }}
          </option>
        </select>
        <svg width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5.5 4L10 1" stroke="#474746" /></svg>
      </div>
      <div v-if="homeType == 'Hus'" class="select-container">
        <label for="floors">Antal etager</label>
        <select id="floors" required v-model="floors">
          <option value="" disabled selected>Vælg antal etager</option>
          <option v-for="amount in 5" :value="amount" :key="amount">
            {{ amount }}
          </option>
        </select>
        <svg width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5.5 4L10 1" stroke="#474746" /></svg>
      </div>
      <div class="select-container">
        <label for="floor">Afdækning af gulve</label>
        <select id="floor" required v-model="floorCoverage">
          <option value="" disabled selected>Vælg afdækning</option>
          <option :value="true">Ja</option>
          <option :value="false">Nej</option>
        </select>
        <svg width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5.5 4L10 1" stroke="#474746" /></svg>
      </div>
      <div class="select-container select-container--full-width">
        <label for="trash">Bortskafning af affald</label>
        <select id="trash" required v-model="trashRemoval">
          <option value="" disabled selected>Vælg bortskafning af affald</option>
          <option :value="true">Ja</option>
          <option :value="false">Nej</option>
        </select>
        <svg width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5.5 4L10 1" stroke="#474746" /></svg>
      </div>
      <ButtonCheckboxes></ButtonCheckboxes>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapGetters, mapActions } from 'vuex'

import ButtonCheckboxes from '@/components/calculator/ui/ButtonCheckboxes'

export default {
  name: 'FirstPhase',
  data() {
    return {
      homeTypes: ['Lejlighed', 'Hus'],
      levels: [
        { name: 'st. th', number: 0 },
        { name: 'st. tv', number: 0 },
        { name: '1. tv', number: 1 },
        { name: '1. tv', number: 1 },
        { name: '2. tv', number: 2 },
        { name: '2. tv', number: 2 },
        { name: '3. tv', number: 3 },
        { name: '3. tv', number: 3 },
        { name: '4. tv', number: 4 },
        { name: '4. tv', number: 4 },
        { name: '5. tv', number: 5 },
        { name: '5. tv', number: 5 },
        { name: '6. tv', number: 6 },
        { name: '6. tv', number: 6 },
      ],
    }
  },
  components: {
    ButtonCheckboxes,
  },
  methods: {
    ...mapActions(['getOptionalSteps', 'getZips']),
  },
  async mounted() {
    if (this.zips.length == 0) await this.getZips()
    if (this.optionalSteps.length == 0) await this.getOptionalSteps()
  },
  computed: {
    ...mapGetters(['optionalSteps', 'zips']),
    ...mapFields([
      'project.firstPhase.danishZip',
      'project.firstPhase.homeType',
      'project.firstPhase.floors',
      'project.firstPhase.level',
      'project.firstPhase.floorCoverage',
      'project.firstPhase.trashRemoval',
    ]),
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared-styles/calculator.scss';
@import '@/assets/shared-styles/select.scss';
@import '@/assets/shared-styles/radio.scss';
</style>
