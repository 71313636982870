<template>
  <div class="image__container">
    <label class="image__label">{{ label }}</label>
    <div v-if="imageData && progress !== 100" class="image__progress-container">
      <progress class="image__progress" :value="progress" max="100" :data-label="`${progress.toFixed()}%`"></progress>
    </div>
    <button v-if="!imageData && !imageURL" @click="onClick" class="button button--ghost button--small">Tilføj fil</button>
    <input type="file" ref="input" style="display: none" @change="uploadImage" accept="image/*" />
    <div class="image__preview-container" v-if="imageURL">
      <img class="image__preview" :src="imageURL" />
      <span @click="deleteImage()" class="image__delete">Fjern</span>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/storage'

import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      imageData: null,
      imageName: null,
      imageURL: null,
      progress: 0,
    }
  },
  props: {
    index: Number,
    label: String,
  },
  mounted() {
    if (this.images[this.index]) {
      this.progress = 100
      this.imageURL = this.images[this.index].url
      this.imageName = this.images[this.index].name
    }
  },
  methods: {
    onClick() {
      this.$refs.input.click()
    },
    uploadImage(event) {
      this.progress = 0
      this.imageData = event.target.files[0]
      this.imageName = this.imageData.name
      const storageRef = firebase
        .storage()
        .ref(`${this.imageName}`)
        .put(this.imageData)
      storageRef.on(
        `state_changed`,
        snapshot => {
          this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
        error => {
          console.log(error)
        },
        async () => {
          this.progress = 100
          this.imageURL = await storageRef.snapshot.ref.getDownloadURL()
          const payload = { index: this.index, name: this.imageName, url: this.imageURL }
          this.$store.commit('addImage', payload)
        }
      )
    },
    deleteImage() {
      this.$confirm({
        message: `Er du sikker på du vil fjerne dette billede?`,
        button: {
          yes: 'Ja',
          no: 'Nej',
        },
        callback: confirm => {
          if (confirm) {
            const storageRef = firebase.storage().ref(`${this.imageName}`)
            storageRef
              .delete()
              .then(() => {
                this.$store.commit('removeImage', this.imageURL)
                this.imageData = null
                this.imageName = null
                this.imageURL = null
              })
              .catch(error => {
                console.log(error)
              })
          }
        },
      })
    },
  },
  computed: {
    ...mapGetters(['images']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared-styles/button.scss';

.image__container {
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  grid-column: 1 / -1;
  border-bottom: 1px solid #ccc;
}
.image__label {
  margin-right: auto;
}
.image__progress-container {
  display: flex;
  align-items: center;
}
.image__progress {
  position: relative;
  appearance: none;
  width: 120px;
  height: 30px;
  border: 1px solid #333;
  &::-webkit-progress-bar {
    background-color: white;
  }
  &::-webkit-progress-value {
    background-color: #333;
  }
  &:before {
    content: attr(data-label);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.image__preview-container {
  position: relative;
  display: flex;
  background: #f1efee;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  width: 75px;
  height: 75px;
  padding: 0.5rem;
}
.image__preview {
  width: 100%;
}
.image__delete {
  position: absolute;
  background: #808080;
  font-size: 0.5rem;
  color: white;
  padding: 0 0.25rem;
  right: 0.5rem;
  top: 0.5rem;
  cursor: pointer;
  &:hover {
    background: #333;
  }
}
</style>
