<template>
  <div class="success">
    <!-- prettier-ignore -->
    <svg width="85" height="85" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="42.5" cy="42.5" r="41" transform="rotate(-180 42.5 42.5)" stroke="url(#paint0_angular)" stroke-width="3"/><path d="M27.625 42.5l12.75 8.5 17-17" stroke="#474746" stroke-width="3"/><defs><radialGradient id="paint0_angular" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(90 0 42.5) scale(42.5)"><stop/><stop offset="1" stop-opacity="0"/></radialGradient></defs></svg>
    <h2>Anmodning afsendt!</h2>
    <p>Du vil modtage en mail, som bekræftigelse på din anmodning.</p>
    <div class="success__push" @click="$router.push('/')">
      <span>Gå til forsiden</span>
      <svg width="15" height="9" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke="#474746" d="M1 4.5h14M4 .5l-3 4 3 4" /></svg>
    </div>
  </div>
</template>

<script>
import emailApi from '@/services/email'
import { mapGetters } from 'vuex'

export default {
  name: 'Success',
  async mounted() {
    const emailData = {
      to: this.project.delivery.email,
      message: {
        subject: 'Tak for din anmodning!',
        text: 'Tak for din anmodning. Vi bekræfter at den er at den er modtaget og vil kontaktet dig hurtigst muligt. De bedste hilsner. Monto CPH',
        html: `
          <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
          <html xmlns="http://www.w3.org/1999/xhtml" lang="en" xml:lang="en">
            <head>
              <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
              <title>Tak for din andmodning</title>
            </head>
            <body style="font-family: Avenir, Helvetica, Arial, sans-serif; color:#333">
              <h1 style="font-size: 20px">Kære ${this.project.delivery.contactName}</h1>
              <p>Tak for din anmodning.</p>
              <p>Vi bekræfter modtagelsen og vil kontakte dig så snart vi har gennemgået den.</p>
              <p>Skulle du have nogen spørgsmål er du velkommen til at ringe på telefon 71647612</p>
              <span>De bedste hilsner</span></br>
              <span>Monto CPH</span>
            </body>
          </html>`,
      },
    }

    if (this.project.delivery.email.length > 5) await emailApi.sendEmail(emailData)
  },
  computed: {
    ...mapGetters(['project']),
  },
  beforeDestroy() {
    this.$store.commit('resetState')
  },
}
</script>

<style lang="scss" scoped>
.success {
  text-align: center;
  background-color: #f1efee;
  padding: 2rem 1.25rem;
  h2 {
    color: #474746;
  }
  > svg {
    margin-bottom: 1rem;
  }
  .success__push {
    margin-top: 4rem;
    color: #474746;
    cursor: pointer;
    font-size: 0.935rem;
    &:hover {
      color: #111111;
      svg > path {
        stroke: #111111;
      }
    }
    svg {
      margin-left: 0.5rem;
      transform: scaleX(-1);
    }
  }
}

@media (min-width: 500px) {
  .success {
    padding: 7rem 5rem 3rem 5rem;
  }
}
</style>
