<template>
  <div v-if="!firstStep" class="back-button" @click="onPrevious">
    <svg width="15" height="9" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke="#474746" d="M1 4.5h14M4 .5l-3 4 3 4" /></svg>
    <span>Forrige trine</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  methods: {
    onPrevious() {
      const isNotFirst = Number(this.$route.params.instance) != 1
      const hasPreviousInstance = Number(this.$route.params.instance) >= this.steps[this.currentStepIndex].instances

      if (hasPreviousInstance && isNotFirst) {
        const currentStep = this.steps[this.currentStepIndex].slug
        const previousInstance = Number(this.$route.params.instance) - 1
        this.$router.push(`/calculator/${currentStep}/${previousInstance}`)
      } else {
        const previousStep = this.steps[this.currentStepIndex - 1].slug
        const previousStepInstances = this.steps[this.currentStepIndex - 1].instances
        this.$router.push(`/calculator/${previousStep}/${previousStepInstances}`)
      }
    },
  },
  computed: {
    ...mapGetters(['steps']),
    totalSteps() {
      return this.steps.length
    },
    currentStepIndex() {
      return this.steps.findIndex(step => step.slug === this.$route.params.step)
    },
    firstStep() {
      return this.steps.findIndex(step => step.slug === this.$route.params.step) == 0
    },
  },
}
</script>

<style lang="scss" scoped>
.back-button {
  color: #474746;
  cursor: pointer;
  font-size: 0.935rem;
  &:hover {
    color: #111111;
    svg > path {
      stroke: #111111;
    }
  }
  svg {
    margin-right: 0.5rem;
  }
}
@media (min-width: 500px) {
  .clear {
    font-size: 1rem;
  }
}
</style>
