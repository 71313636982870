<template>
  <div class="steps">
    <template v-for="(step, index) in steps">
      <div class="step" v-for="instance in step.instances" :key="`${index}-${instance}`">
        <div class="circle" :class="{ active: isActive(index, instance), done: isDone(index, instance) }">
          <svg width="8" height="6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M.5 3l3 2 4-4" stroke="#fff" /></svg>
        </div>
        <span v-if="$store.getters.optionalStepNameBySlug(step.slug)">{{ $store.getters.optionalStepNameBySlug(step.slug) }} ({{ instance }})</span>
        <span v-if="$store.getters.requiredStepNameBySlug(step.slug)">{{ $store.getters.requiredStepNameBySlug(step.slug) }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  methods: {
    isActive(index, instance) {
      return index == this.currentStepIndex && this.$route.params.instance == instance ? true : false
    },
    isDone(index, instance) {
      const isCurrentStep = index == this.currentStepIndex
      const isPreviousInstance = isCurrentStep && this.$route.params.instance > instance

      return index < this.currentStepIndex || isPreviousInstance ? true : false
    },
  },
  computed: {
    ...mapGetters(['steps']),
    currentStepIndex() {
      return this.steps.findIndex(step => step.slug === this.$route.params.step)
    },
  },
}
</script>

<style lang="scss" scoped>
.steps {
  position: fixed;
  left: 10%;
  top: 40%;
  transform: translateY(-50%);
  min-height: 300px;
}
.step {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}
.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin-right: 0.75rem;
  &.active {
    border: 1px solid #474746;
    + span {
      color: #474746;
    }
  }
  &.done {
    border: 1px solid #474746;
    background-color: #474746;
    color: #474746;
  }
}
</style>
