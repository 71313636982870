<template>
  <button class="button button--margin-left-auto" @click="onNext">{{ label }}</button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions(['setItemsToProject', 'createProject']),
    onNext() {
      if (this.totalSteps == 1) return

      if (!this.isLastStep) {
        if (this.currentStepIndex == 0) this.setItemsToProject(this.selectedItems)

        const hasNextInstance = Number(this.$route.params.instance) < this.steps[this.currentStepIndex].instances

        if (hasNextInstance) {
          const currentStep = this.steps[this.currentStepIndex].slug
          const nextInstance = Number(this.$route.params.instance) + 1
          this.$router.push(`/calculator/${currentStep}/${nextInstance}`)
        } else {
          const nextStep = this.steps[this.currentStepIndex + 1].slug
          this.$router.push(`/calculator/${nextStep}/1`)
        }
      } else {
        this.createProject(this.project)
        this.$router.push(`/calculator/success`)
      }
    },
  },
  computed: {
    ...mapGetters(['items', 'steps', 'optionalSteps', 'isLastStep', 'selectedItems', 'project']),
    totalSteps() {
      return this.steps.length
    },
    label() {
      return !this.isLastStep ? 'Næste' : 'Send anmodning'
    },
    isLastStep() {
      return this.$route.params.step == this.steps.slice(-1)[0].slug
    },
    currentStepIndex() {
      return this.steps.findIndex(step => step.slug === this.$route.params.step)
    },
    selectedItems() {
      const selectedSteps = this.optionalSteps.filter(step => step.selected)
      let itemsCopy = []
      itemsCopy = this.items.slice()

      let matches = []

      selectedSteps.forEach(selectedStep => {
        for (let index = 0; index < selectedStep.instances; index++) {
          itemsCopy.forEach(item => {
            if (item.step == selectedStep.slug) {
              let itemToBeModified = Object.assign({}, item)
              itemToBeModified.instance = index + 1
              item = itemToBeModified
              matches.push(item)
            }
          })
        }
      })

      return matches
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared-styles/button.scss';
</style>
