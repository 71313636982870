<template>
  <div class="button-container">
    <span class="header">Inventar type</span>
    <div class="button-checkboxes">
      <template v-for="(optionalStep, index) in optionalSteps">
        <label class="container" :key="optionalStep.slug">
          <input type="checkbox" @change="updateSteps(optionalStep)" v-model="optionalSteps[index].selected" />
          <span class="checkmark">{{ optionalStep.name }}</span>
        </label>
      </template>
    </div>
    <template v-for="(optionalStep, index) in optionalSteps">
      <div v-if="optionalStep.selected" class="select-container select-container--full-width select-container--margin-top" :key="optionalStep.slug">
        <label for="home-type">Antal {{ optionalStep.name.toLowerCase() }}</label>
        <select id="home-type" @change="updateSteps(optionalStep)" required v-model="optionalSteps[index].instances">
          <option value="" disabled selected>Vælg antal {{ optionalStep.name.toLowerCase() }}</option>
          <option v-for="n in 5" :value="n" :key="n">
            {{ n }}
          </option>
        </select>
        <svg width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5.5 4L10 1" stroke="#474746" /></svg>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  methods: {
    updateSteps(optionalStep) {
      const optionalStepExists = this.steps.some(step => step.slug === optionalStep.slug)
      const indexOfStep = this.steps.findIndex(step => step.slug === optionalStep.slug)
      const instanceHasChanged = indexOfStep > -1 ? optionalStep.instances !== this.steps[indexOfStep].instances : false

      !optionalStepExists
        ? this.$store.commit('addOptionalStep', { slug: optionalStep.slug, instances: optionalStep.instances })
        : this.$store.commit('removeOptionalStep', { slug: optionalStep.slug, instances: optionalStep.instances })

      if (instanceHasChanged) {
        this.$store.commit('removeOptionalStep', { slug: optionalStep.slug, instances: optionalStep.instances })
        this.$store.commit('addOptionalStep', { slug: optionalStep.slug, instances: optionalStep.instances })
      }
    },
  },
  computed: {
    ...mapGetters(['steps', 'optionalSteps']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared-styles/select.scss';

.button-container {
  grid-column: 1/-1;
}

.header {
  font-size: 0.8125rem;
  margin-bottom: 0.5rem;
  display: block;
}
.button-checkboxes {
  display: flex;

  flex-wrap: wrap;
  grid-column: 1 / -1;
}

.container {
  cursor: pointer;
  user-select: none;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #d0cecc;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  display: block;
  font-size: 0.8125rem;
  padding: 0.5rem 1.25rem;
}

.container input:checked ~ .checkmark {
  background-color: #d0cecc;
  color: #474746;
}
</style>
