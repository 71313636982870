<template>
  <div class="clear" @click="onClear">
    <span>Annuller beregner</span>
    <svg width="11" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l9 9m0-9l-9 9" stroke="#474746" /></svg>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  methods: {
    onClear() {
      this.$confirm({
        message: `Er du sikker på du vil annulere alle dine indtastninger?`,
        button: {
          yes: 'Ja',
          no: 'Nej',
        },
        callback: confirm => {
          if (confirm) {
            if (this.currentStepIndex !== 0) this.$router.push('/calculator/first-phase/1')
            this.$store.commit('resetState')
          }
        },
      })
    },
  },
  computed: {
    ...mapGetters(['steps']),
    currentStepIndex() {
      return this.steps.findIndex(step => step.slug === this.$route.params.step)
    },
  },
}
</script>

<style lang="scss" scoped>
.clear {
  color: #474746;
  margin-left: auto;
  cursor: pointer;
  font-size: 0.935rem;
  &:hover {
    color: #111111;
    svg > path {
      stroke: #111111;
    }
  }
  svg {
    margin-left: 0.5rem;
  }
}

@media (min-width: 500px) {
  .clear {
    font-size: 1rem;
  }
}
</style>
