<template>
  <div>
    <h1 class="calculator__header">{{ $store.getters.optionalStepNameBySlug(this.$route.params.step) }} inventar</h1>
    <p class="calculator__description">Alle fronter skal pakkes ind og markeres på indpakningen, så de ikke behøver pakkes op før på montering.</p>

    <div class="calculator__container">
      <template v-if="isLoading">
        <h2 class="calculator__subheader calculator__subheader--placeholder">Placeholder</h2>
        <div class="calculator__card"></div>
        <h2 class="calculator__subheader calculator__subheader--placeholder">Placeholder</h2>
        <div class="calculator__card"></div>
      </template>

      <template v-else v-for="subStep in currentSubSteps">
        <h2 class="calculator__subheader" :key="`header-${subStep.id}`">{{ subStep.name }}</h2>
        <div class="calculator__card" :key="`card-${subStep.id}`">
          <template v-for="(item, index) in projectItems">
            <template v-if="subStep.id == item.subStep && $route.params.instance == item.instance">
              <div v-if="item.type == 'unit'" class="select-container" :key="index">
                <label>
                  {{ item.name }}
                  <!-- prettier-ignore -->
                  <svg v-tooltip.right-start="{content: item.info, offset: '10px'}" v-if="item.info" class="select__info" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path fill="#d1d0d0" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/></svg>
                </label>
                <select required v-model="item.amount">
                  <option value="" disabled selected>{{ item.placeholder }}</option>
                  <option v-for="n in item.maxAmount - item.minAmount + 1" :value="n" :key="n">{{ n - 1 + item.minAmount }} {{ item.unit }}</option>
                </select>
                <svg width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5.5 4L10 1" stroke="#474746" /></svg>
              </div>

              <div v-if="item.type == 'custom'" class="select-container" :key="index">
                <label>
                  {{ item.name }}
                  <!-- prettier-ignore -->
                  <svg v-tooltip.right-start="{content: item.info, offset: '10px'}" v-if="item.info" class="select__info" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path fill="#d1d0d0" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/></svg>
                </label>
                <select required v-model="item.customOption">
                  <option value="" disabled selected>{{ item.placeholder }}</option>
                  <option v-for="customOption in item.customOptions" :value="customOption" :key="customOption.name">{{ customOption.name }}</option>
                </select>
                <svg width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5.5 4L10 1" stroke="#474746" /></svg>
              </div>

              <div v-if="item.type == 'customFixed'" class="select-container" :key="index">
                <label>
                  {{ item.name }}
                  <!-- prettier-ignore -->
                  <svg v-tooltip.right-start="{content: item.info, offset: '10px'}" v-if="item.info" class="select__info" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path fill="#d1d0d0" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/></svg>
                </label>
                <select required v-model="item.customOptionFixed">
                  <option value="" disabled selected>{{ item.placeholder }}</option>
                  <option v-for="customOptionFixed in item.customOptionsFixed" :value="customOptionFixed" :key="customOptionFixed.name">{{ customOptionFixed.name }}</option>
                </select>
                <svg width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5.5 4L10 1" stroke="#474746" /></svg>
              </div>
            </template>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'OptionalStep',
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    ...mapActions(['getSubSteps', 'setCurrentStep']),
  },
  async created() {
    this.isLoading = true
    await this.setCurrentStep(this.$route.params.step)
    if (!this.subSteps) await this.getSubSteps()
    this.isLoading = false
  },
  watch: {
    $route() {
      this.isLoading = true
      this.setCurrentStep(this.$route.params.step)
      this.isLoading = false
    },
  },
  computed: {
    ...mapGetters(['currentSubSteps', 'projectItems']),
  },
}
</script>
<style lang="scss">
.tooltip {
  .tooltip-inner {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    background: #d1cfcd;
    color: #474746;
    max-width: 300px;
    padding: 1rem;
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/shared-styles/calculator.scss';
@import '@/assets/shared-styles/select.scss';
@import '@/assets/shared-styles/radio.scss';
</style>
